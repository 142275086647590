import React from 'react';

import Campaign from './components/campaign';
import Customers from './components/customers';
import Header from './components/header';
import HowWorks from './components/howWorks';
import Promises from './components/promises';
import Publicity from './components/publicity';

function Company() {
  return (
    <>
      <Header />
      <Publicity />
      <HowWorks />
      <Customers />
      <Campaign />
      <Promises />
    </>
  );
}

export default Company;
