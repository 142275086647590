import React from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography
} from '@mui/material';

import FacebookIcon from '~/assets/icons/facebook.svg';
import InstagramIcon from '~/assets/icons/instagram.svg';
import TikTokIcon from '~/assets/icons/tikTok.svg';
import TwitterIcon from '~/assets/icons/twitter.svg';
import WhatsAppIcon from '~/assets/icons/whatsApp.svg';
import {
  Section,
  Title,
  CompanyButton,
  SocialIcon,
  BgImage,
  Blink
} from './styles';

function Header() {
  return (
    <Section>
      <Container
        maxWidth="desktop"
        sx={{ paddingTop: '170px', position: 'relative', zIndex: 1 }}
      >
        <Grid container>
          <Grid desktop={6} item>
            <Title variant="h1">
              encontre <br />
              sua{' '}
              <span>
                influência<Blink>_</Blink>
              </span>
            </Title>
            <Box sx={{ marginTop: 3, color: 'text.secondary' }}>
              <Typography variant="subtitle1">
                Somos o ponto de encontro de marcas e <br /> influenciadores
                ideais para a sua próxima campanha.
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{ marginTop: 3 }}
              >
                Começar agora mesmo como:
              </Typography>
            </Box>

            <Box sx={{ marginTop: 2 }}>
              <Button variant="contained" color="primary" size="large">
                Influencer_
              </Button>

              <CompanyButton variant="outlined" size="large">
                Empresa_
              </CompanyButton>
            </Box>

            <List sx={{ marginTop: 5 }}>
              <ListItem disableGutters>
                <Link href="" sx={{ lineHeight: 0 }}>
                  <SocialIcon src={FacebookIcon} height={20} uniquifyIDs />
                </Link>
              </ListItem>

              <ListItem>
                <Link href="" sx={{ lineHeight: 0 }}>
                  <SocialIcon src={InstagramIcon} height={20} uniquifyIDs />
                </Link>
              </ListItem>

              <ListItem>
                <Link href="" sx={{ lineHeight: 0 }}>
                  <SocialIcon src={TwitterIcon} height={20} uniquifyIDs />
                </Link>
              </ListItem>

              <ListItem>
                <Link href="" sx={{ lineHeight: 0 }}>
                  <SocialIcon src={TikTokIcon} height={20} uniquifyIDs />
                </Link>
              </ListItem>

              <ListItem>
                <Link href="" sx={{ lineHeight: 0 }}>
                  <SocialIcon src={WhatsAppIcon} height={20} uniquifyIDs />
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid desktop={6} item />
        </Grid>
      </Container>

      <BgImage />
    </Section>
  );
}

export default Header;
