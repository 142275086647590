import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';

export const Section = styled('section')(() => ({
  position: 'relative'
}));

export const Title = styled(MuiTypography)(({ theme }) => ({
  color: 'white',
  span: {
    color: theme.palette.primary.main
  }
}));

export const MoviesPublicity = styled(MuiBox)(() => ({
  width: 300,
  height: 360,
  position: 'relative',
  video: {
    width: '100%',
    height: '100%',
    borderRadius: 18
  },
  p: {
    color: '#FFF',
    position: 'absolute',
    top: 8,
    right: 18
  }
}));

export const Subtitle = styled(MuiTypography)(({ theme }) => ({
  span: {
    color: theme.palette.primary.light
  }
}));

export const Overlay = styled('div')(({ theme }) => ({
  width: '100%',
  height: 635,
  backgroundColor: theme.palette.primary.light,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: -1
}));
