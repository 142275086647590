import React from 'react';

import Customers from './components/customers';
import Header from './components/header';
import HowWorks from './components/howWorks';
import Promises from './components/promises';
import Publicity from './components/publicity';

function Design() {
  return (
    <>
      <Header />
      <Publicity />
      <HowWorks />
      <Customers />
      <Promises />
    </>
  );
}

export default Design;
