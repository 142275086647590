import React, { createContext } from 'react';

import AllThemes from '~/themes';

const ThemesContext = createContext({
  themes: AllThemes,
  updateTheme: () => {}
});

export const ThemesProvider = ({ themes, children }) => {
  return (
    <ThemesContext.Provider value={{ allThemes: themes || AllThemes }}>
      {children}
    </ThemesContext.Provider>
  );
};

export default ThemesContext;
