import React from 'react';

import {
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography
} from '@mui/material';

import LogoWhite from '~/assets/images/logos/logotipo_white.png';

import { Main, Bar, Logo } from './styles';

function Footer() {
  return (
    <Main>
      <Container maxWidth="desktop">
        <Grid spacing={3} container>
          <Grid desktop={4} item>
            <Typography variant="subtitle2">
              Telão. Grande impacto. O desafio era criar um display maior
              enquanto mal expandia as dimensões do relógio em si. Para isso, o
              display foi completamente reformulado reduzindo as bordas em 40%,
              permitindo mais área de tela do que as séries 6 e series 3. Isso é
              algo para sorrir.
            </Typography>
          </Grid>

          <Grid desktop={2} item>
            <Typography gutterBottom>INFLUENCER</Typography>

            <List sx={{ display: 'block' }} disablePadding>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Home
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Sobre
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Como funciona
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Feedback
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Cadastro
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid desktop={2} item>
            <Typography gutterBottom>EMPRESAS</Typography>

            <List sx={{ display: 'block' }} disablePadding>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Home
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Sobre
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Como funciona
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Cadastro
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid desktop={2} item>
            <Typography gutterBottom>DESIGN</Typography>

            <List sx={{ display: 'block' }} disablePadding>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Home
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Sobre
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Como funciona
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Cadastro
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid desktop={2} item>
            <Typography gutterBottom>LEGAL</Typography>

            <List sx={{ display: 'block' }} disablePadding>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Termos e condições
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Política de privacidade
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Política de cookies
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Configurações de cookies
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid desktop={2} item>
            <Typography gutterBottom>AJUDA</Typography>

            <List sx={{ display: 'block' }} disablePadding>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Suporte
                </Link>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <Link
                  href="#"
                  variant="subtitle2"
                  color="#D4D4D4"
                  underline="none"
                >
                  Contato
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Bar>
          <Logo src={LogoWhite} alt="logo" />

          <Typography color="#D4D4D4">
            Copyright © 2021-2031 Influence Pass. CNPJ 43.473.212/0001-07.
          </Typography>
        </Bar>
      </Container>
    </Main>
  );
}

export default Footer;
