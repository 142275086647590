import companyTheme from './company';
import designTheme from './design';
import influencerTheme from './influencer';

const themes = {
  company: companyTheme,
  design: designTheme,
  influencer: influencerTheme
};

export default themes;
