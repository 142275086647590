import styled from '@emotion/styled';
import MuiGrid from '@mui/material/Grid';
import { Swiper } from 'swiper/react';

export const Section = styled('section')(() => ({
  position: 'relative'
}));

export const Content = styled(MuiGrid)(({ theme }) => ({
  height: '100%',
  background: theme.palette.background.paper,
  borderRadius: 8
}));

export const Carrousel = styled(Swiper)(() => ({
  position: 'initial !important',
  '& .swiper-button-prev, .swiper-button-next': {
    color: '#F1F1F1'
  },
  '& .swiper-pagination': {
    bottom: '30px !important'
  },
  '& .swiper-pagination .swiper-pagination-bullet': {
    background: '#FFF',
    boxShadow: '0px 0px 10px #00000029',
    opacity: 1
  }
}));
