import React from 'react';

import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { Navigation, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Section, Content, Carrousel } from './styles';

function HowWorks() {
  return (
    <Section>
      <Container maxWidth="desktop" sx={{ height: 560, paddingTop: 13 }}>
        <Content spacing={3} container>
          <Grid desktop={6} sx={{ position: 'relative' }} item>
            <Typography variant="body2" gutterBottom>
              como funciona
            </Typography>

            <Typography variant="h5" sx={{ color: 'text.secondary' }}>
              Entenda como é <br /> simples e rápido
              <br /> começar a criar sua
              <br /> influência_
            </Typography>

            <Card
              sx={{
                width: 300,
                position: 'absolute',
                bottom: 0,
                borderRadius: '32px 32px 0 0',
                boxShadow: '0px 0px 20px #0000000f'
              }}
            >
              <CardMedia
                height="180"
                component="img"
                image="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BgQAAAADDoPlTX+AIVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwDcaiAAFXD1ujAAAAAElFTkSuQmCC"
              />
            </Card>
          </Grid>

          <Grid desktop={6} item>
            <Carrousel
              slidesPerView={1}
              navigation={true}
              pagination={true}
              modules={[Navigation, Pagination]}
            >
              <SwiperSlide>
                <Box pt={10} pr={5}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.light',
                      fontWeight: 300,
                      textAlign: 'right',
                      marginBottom: 4
                    }}
                  >
                    Passo 1
                  </Typography>

                  <Typography
                    variant="h5"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 500,
                      textAlign: 'right',
                      marginBottom: 4
                    }}
                  >
                    Faça uma busca filtrando os influenciadores
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 300,
                      textAlign: 'right'
                    }}
                  >
                    Encontre os perfis ideais para sua marca, utilizando nossos
                    filtros de segmento, cidade, métricas e muito mais!
                  </Typography>
                </Box>
              </SwiperSlide>

              <SwiperSlide>
                <Box pt={10} pr={5}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.light',
                      fontWeight: 300,
                      textAlign: 'right',
                      marginBottom: 4
                    }}
                  >
                    Passo 2
                  </Typography>

                  <Typography
                    variant="h5"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 500,
                      textAlign: 'right',
                      marginBottom: 4
                    }}
                  >
                    Faça uma busca filtrando os influenciadores
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 300,
                      textAlign: 'right'
                    }}
                  >
                    Encontre os perfis ideais para sua marca, utilizando nossos
                    filtros de segmento, cidade, métricas e muito mais!
                  </Typography>
                </Box>
              </SwiperSlide>

              <SwiperSlide>
                <Box pt={10} pr={5}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.light',
                      fontWeight: 300,
                      textAlign: 'right',
                      marginBottom: 4
                    }}
                  >
                    Passo 3
                  </Typography>

                  <Typography
                    variant="h5"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 500,
                      textAlign: 'right',
                      marginBottom: 4
                    }}
                  >
                    Faça uma busca filtrando os influenciadores
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 300,
                      textAlign: 'right'
                    }}
                  >
                    Encontre os perfis ideais para sua marca, utilizando nossos
                    filtros de segmento, cidade, métricas e muito mais!
                  </Typography>
                </Box>
              </SwiperSlide>
            </Carrousel>
          </Grid>
        </Content>
      </Container>
    </Section>
  );
}

export default HowWorks;
