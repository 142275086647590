import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';

export const Title = styled(MuiTypography)(({ theme }) => ({
  span: {
    color: theme.palette.primary.main
  }
}));

export const MoviesPublicity = styled(MuiBox)(() => ({
  width: 300,
  height: 360,
  position: 'relative',
  video: {
    width: '100%',
    height: '100%',
    borderRadius: 18
  },
  p: {
    color: '#FFF',
    position: 'absolute',
    top: 8,
    right: 18
  }
}));
