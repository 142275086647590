import SVG from 'react-inlinesvg';

import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import MuiTypography from '@mui/material/Typography';

import BackgroundImage from '~/assets/images/company/background.png';

export const Section = styled('section')(() => ({
  position: 'relative'
}));

export const Title = styled(MuiTypography)(({ theme }) => ({
  span: {
    color: theme.palette.primary.main
  }
}));

export const Blink = styled('span')(() => ({
  animation: 'animate 1.5s linear infinite',
  '@keyframes animate': {
    '0%': {
      opacity: 0
    },
    '50%': {
      opacity: 0.7
    },
    '100%': {
      opacity: 0
    }
  }
}));

export const InfluencerButton = styled(MuiButton)(({ theme }) => ({
  marginRight: 16,
  color: theme.palette.text.primary,
  borderColor: theme.palette.text.secondary,
  '&:hover': {
    background: 'transparent',
    borderColor: theme.palette.text.secondary
  }
}));

export const CompanyButton = styled(MuiButton)(({ theme }) => ({
  '&:hover': {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  }
}));

export const SocialIcon = styled(SVG)(({ theme }) => ({
  color: theme.palette.text.primary
}));

export const BgImage = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: 'contain',
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat'
}));
