import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';
import MuiTypography from '@mui/material/Typography';

export const Title = styled(MuiTypography)(({ theme }) => ({
  span: {
    color: theme.palette.primary.main
  }
}));

export const Content = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  height: 200,
  color: theme.palette.text.secondary,
  backgroundColor: 'white',
  borderRadius: 16,
  border: `2px solid ${theme.palette.primary.light}`,
  boxShadow: 'none'
}));

export const ShadowPrimary = styled('div')(() => ({
  width: '87%',
  height: 220,
  backgroundColor: '#F8F8F8',
  borderRadius: 16,
  position: 'absolute',
  top: 24,
  zIndex: -2
}));

export const ShadowSecondary = styled('div')(() => ({
  width: '90%',
  height: 210,
  backgroundColor: '#F1F1F1',
  borderRadius: 32,
  position: 'absolute',
  top: 24,
  zIndex: -1
}));
