import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';

export const Logo = styled('img')(() => ({
  height: 50
}));

export const RegisterButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 26,
  color: theme.type === 'design' ? theme.palette.primary.dark : 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}));
