import styled from '@emotion/styled';

export const Main = styled('footer')(() => ({
  paddingTop: 40,
  paddingBottom: 50,
  color: 'white',
  backgroundColor: '#707070',
  link: {
    color: '#D4D4D4'
  }
}));

export const Logo = styled('img')(() => ({
  height: 60,
  marginTop: -10,
  marginLeft: -11
}));

export const Bar = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 80,
  p: {
    width: '80%',
    textAlign: 'center'
  }
}));
