import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import MuiGrid from '@mui/material/Grid';
import { Swiper } from 'swiper/react';

export const Section = styled('section')(({ theme }) => ({
  padding: '30px 0',
  marginTop: 13,
  backgroundColor: theme.palette.primary.dark
}));

export const LeftItem = styled(MuiGrid)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const RegisterButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.primary.dark,
  fontWeight: 600,
  '&:hover': {
    backgroundColor: 'white'
  }
}));

export const Carrousel = styled(Swiper)(() => ({
  height: 530,
  '& .swiper-wrapper': {
    alignItems: 'flex-end'
  },
  '& .swiper-slide': {
    width: 389,
    ':nth-of-type(2n)': {
      marginRight: 50
    }
  }
}));
