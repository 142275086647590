import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';

import useTheme from '~/hooks/useThemes';

import { setCurrentTheme } from '~/store/actions/theme';

import Footer from './components/footer';
import Topbar from './components/topbar';

import { Container } from './styles';

function MainLayout() {
  const currentTheme = useSelector(({ currentTheme }) => currentTheme);

  const { allThemes } = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const theme = pathname.split('/')[1];

    if (allThemes[theme]) {
      dispatch(setCurrentTheme(theme));
    }
  }, []);

  return (
    <ThemeProvider theme={allThemes[currentTheme]}>
      <CssBaseline />

      <StyledEngineProvider injectFirst>
        <Topbar />

        <Container>
          <Outlet />
          <Footer />
        </Container>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default MainLayout;
