import React from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, Typography } from '@mui/material';

import {
  Section,
  Content,
  ContentContainer,
  Bar,
  RegisterButton
} from './styles';

function Promises() {
  return (
    <Section>
      <Content>
        <ContentContainer maxWidth="desktop">
          <Typography variant="h6" color="primary.light" fontWeight={400}>
            Faça publis para as grandes empresas e <br /> ganhe muito com isso!
          </Typography>

          <Typography variant="h4" color="primary.light">
            Nunca foi tão fácil <br /> ser influencer_
          </Typography>
        </ContentContainer>
      </Content>

      <Box sx={{ backgroundColor: 'primary.main' }}>
        <Bar maxWidth="desktop">
          <Typography variant="h6" color="primary.dark" fontWeight={400}>
            Começar agora mesmo:
          </Typography>

          <RegisterButton endIcon={<ArrowRightAltIcon />}>
            Fazer cadastro
          </RegisterButton>
        </Bar>
      </Box>
    </Section>
  );
}

export default Promises;
