import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material';

import { setCurrentTheme } from '~/store/actions/theme';

import CompanyLogo from '~/assets/images/company/logotipo.png';
import DesignLogo from '~/assets/images/design/logotipo.png';
import InfluencerLogo from '~/assets/images/influencer/logotipo.png';

import { Logo, RegisterButton } from './styles';

function Topbar() {
  const currentTheme = useSelector(({ currentTheme }) => currentTheme);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  function handleToggleTheme(theme) {
    dispatch(setCurrentTheme(theme));
    navigate(`/${theme}`);
  }

  return (
    <AppBar
      position={scrollTrigger ? 'sticky' : 'absolute'}
      elevation={scrollTrigger ? 1 : 0}
      sx={{ background: scrollTrigger ? '#FFF' : 'transparent' }}
    >
      <Container maxWidth="desktop">
        <Toolbar sx={{ justifyContent: 'space-between' }} disableGutters>
          <Link href="/">
            <Logo
              src={
                currentTheme === 'influencer'
                  ? InfluencerLogo
                  : currentTheme === 'company'
                  ? CompanyLogo
                  : DesignLogo
              }
              alt="logo"
            />
          </Link>

          <List
            sx={{
              color: currentTheme === 'design' && !scrollTrigger && 'white'
            }}
          >
            <ListItem>
              <Typography
                variant="subtitle2"
                color={currentTheme === 'influencer' && 'primary'}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleToggleTheme('influencer')}
              >
                Influencers
              </Typography>
            </ListItem>

            <ListItem>
              <Typography
                variant="subtitle2"
                color={currentTheme === 'company' && 'primary'}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleToggleTheme('company')}
              >
                Empresas
              </Typography>
            </ListItem>

            <ListItem>
              <Typography
                variant="subtitle2"
                color={currentTheme === 'design' && 'primary'}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleToggleTheme('design')}
              >
                Design
              </Typography>
            </ListItem>
          </List>

          <Box>
            <Button
              sx={{
                marginRight: 2,
                color: scrollTrigger ? '#707070' : 'white'
              }}
            >
              Login
            </Button>

            <RegisterButton variant="contained" size="small">
              Cadastre-se
            </RegisterButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Topbar;
