import React from 'react';
import { Provider } from 'react-redux';

import { ThemesProvider } from '~/contexts/themesContext';

import StoreRedux from '~/store';

import MainRoutes from '~/routes';

function App() {
  return (
    <Provider store={StoreRedux}>
      <ThemesProvider>
        <MainRoutes />
      </ThemesProvider>
    </Provider>
  );
}

export default App;
