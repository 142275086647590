import React from 'react';

import { Box, CardContent, Container, Grid, Typography } from '@mui/material';

import ArrowBottom from '~/assets/images/company/arrow_bottom.png';

import { Title, Content, ShadowPrimary, ShadowSecondary } from './styles';

function Campaign() {
  return (
    <section>
      <Container maxWidth="desktop" sx={{ paddingTop: 13 }}>
        <Title variant="h3" align="center">
          Benefícios de se ter influencers <br /> <span>divulgando</span> sua
          campanha_
        </Title>

        <Grid spacing={3} sx={{ marginTop: 13 }} container>
          <Grid desktop={4} sx={{ position: 'relative' }} item>
            <Content>
              <CardContent>
                <Typography variant="h6" fontWeight={500}>
                  + Visualizações
                </Typography>

                <Typography sx={{ marginTop: 4 }}>
                  O seu conteúdo alcança mais pessoas com publicações virais!
                </Typography>
              </CardContent>
            </Content>

            <ShadowPrimary />
            <ShadowSecondary />
          </Grid>

          <Grid desktop={4} sx={{ position: 'relative' }} item>
            <Content>
              <CardContent>
                <Typography variant="h6" fontWeight={500}>
                  + Engajamento
                </Typography>

                <Typography sx={{ marginTop: 4 }}>
                  Quanto mais pessoas verem a sua campanha, mais vão querer
                  adquirir os seus produtos.
                </Typography>
              </CardContent>
            </Content>

            <ShadowPrimary />
            <ShadowSecondary />
          </Grid>

          <Grid desktop={4} sx={{ position: 'relative' }} item>
            <Content>
              <CardContent>
                <Typography variant="h6" fontWeight={500}>
                  + Identificação
                </Typography>

                <Typography sx={{ marginTop: 4 }}>
                  Pessoas querem se sentir conectadas a grupos de pessoas reais
                  e terem semelhanças com elas.
                </Typography>
              </CardContent>
            </Content>

            <ShadowPrimary />
            <ShadowSecondary />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <img src={ArrowBottom} />
        </Box>
      </Container>
    </section>
  );
}

export default Campaign;
