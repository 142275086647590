import React from 'react';

import StarIcon from '@mui/icons-material/Star';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { SwiperSlide } from 'swiper/react';

import { Section, LeftItem, RegisterButton, Carrousel } from './styles';

function Customers() {
  function renderCarrouselItems() {
    const elements = [1, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4];

    return elements.map((index, i) => (
      <SwiperSlide key={i + 1}>
        <Card sx={{ borderRadius: 2 }}>
          <CardHeader
            avatar={<Avatar />}
            title={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItem="center"
              >
                <Typography>Luisa Almeida</Typography>

                <Box>
                  <StarIcon sx={{ color: '#efce4a' }} />
                  <StarIcon sx={{ color: '#efce4a' }} />
                  <StarIcon sx={{ color: '#efce4a' }} />
                  <StarIcon sx={{ color: '#efce4a' }} />
                  <StarIcon sx={{ color: '#efce4a' }} />
                </Box>
              </Box>
            }
          />

          <CardContent>
            <Box sx={{ height: 100, background: '#F1F1F1', borderRadius: 2 }} />
          </CardContent>
        </Card>
      </SwiperSlide>
    ));
  }

  return (
    <Section>
      <Container maxWidth="desktop" sx={{ height: 530 }}>
        <Grid sx={{ height: '100%' }} container>
          <LeftItem desktop={6} item>
            <Typography variant="h3" color="white" fontWeight={500}>
              saiba de quem <br /> já usou_
            </Typography>

            <Typography variant="h5" color="primary.light" fontWeight={400}>
              Com acesso a milhares de
              <br /> aplicativos da App Store bem
              <br /> no pulso, o Apple Watch é o<br /> dispositivo pessoal
              perfeito
              <br /> para o que você gosta de fazer.
              <br /> Surfe, fotografia, astronomia.
            </Typography>

            <Box>
              <Typography
                variant="subtitle2"
                color="white"
                fontWeight={400}
                gutterBottom
              >
                Começar agora mesmo:
              </Typography>

              <RegisterButton variant="contained" size="large">
                Fazer cadastro
              </RegisterButton>
            </Box>
          </LeftItem>

          <Grid desktop={6} item>
            <Carrousel
              direction="vertical"
              slidesPerView={3}
              spaceBetween={150}
            >
              {renderCarrouselItems()}
            </Carrousel>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default Customers;
