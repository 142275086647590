import React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';

import {
  Section,
  MovieTop,
  Title,
  MoviesPublicity,
  Subtitle,
  Overlay
} from './styles';

function Publicity() {
  return (
    <Section position="relative">
      <MovieTop display="flex" justifyContent="center" background>
        <video controls>
          <source src="" type="video/mp4" />
        </video>
      </MovieTop>

      <Container maxWidth="desktop" sx={{ paddingTop: 13 }}>
        <Title variant="h3">
          Trabalhe suas campanhas <br /> de forma estratégica e{' '}
          <span>fature alto_</span>
        </Title>

        <Box p={8}>
          <Grid spacing={8} container>
            <Grid desktop={6} item>
              <Typography sx={{ width: 300, paddingBottom: 6, color: 'white' }}>
                <strong>Telão. Grande impacto.</strong> O desafio era criar um
                display maior enquanto mal expandia as dimensões do relógio em
                si. Para isso, o display foi completamente reformulado reduzindo
                as bordas em 40%, permitindo mais área de tela do que as séries
                6 e series 3. Isso é algo para sorrir.
              </Typography>

              <MoviesPublicity>
                <video controls>
                  <source src="" type="video/mp4" />
                </video>

                <Typography>#publi_</Typography>
              </MoviesPublicity>
            </Grid>

            <Grid
              desktop={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}
              item
            >
              <MoviesPublicity sx={{ marginTop: 6 }}>
                <video controls>
                  <source src="" type="video/mp4" />
                </video>

                <Typography>#publi_</Typography>
              </MoviesPublicity>

              <Typography
                sx={{ width: 300, paddingTop: 6, color: 'text.secondary' }}
                align="right"
              >
                <strong>Mudanças fundamentais de design</strong> foram
                necessárias para alcançar a visão do display sempre-on retina
                maior. Essas mesmas inovações também ajudaram a fazer o cristal
                frontal mais resistente à crack até agora.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Subtitle variant="h3" align="center">
          Todas as melhores <span>#publis</span> <br /> e ganhe muito com isso_
        </Subtitle>
      </Container>

      <Overlay />
    </Section>
  );
}

export default Publicity;
