import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import MuiContainer from '@mui/material/Container';

import PromisesImage from '~/assets/images/influencer/promises.png';

export const Section = styled('section')(() => ({
  marginTop: 64
}));

export const Content = styled('div')(() => ({
  width: '100%',
  height: 500,
  padding: '80px 0',
  backgroundImage: `url(${PromisesImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
}));

export const ContentContainer = styled(MuiContainer)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const Bar = styled(MuiContainer)(() => ({
  height: 100,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const RegisterButton = styled(MuiButton)(() => ({
  fontSize: '1.125rem',
  color: 'white',
  span: {
    marginLeft: 24,
    svg: {
      fontSize: '35px !important'
    }
  }
}));
