import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import MainLayout from '~/components/mainLayout';

import Company from '~/pages/company';
import Design from '~/pages/design';
import Influencer from '~/pages/influencer';

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/influencer" replace />} />

      <Route path="/" element={<MainLayout />}>
        <Route path="/influencer" element={<Influencer />} />
        <Route path="/company" element={<Company />} />
        <Route path="/design" element={<Design />} />
      </Route>

      <Route path="*" element={<Navigate to="/influencer" replace />} />
    </Routes>
  );
}

export default MainRoutes;
