import { createTheme } from '@mui/material';

const companyTheme = createTheme({
  type: 'company',
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 600,
      laptop: 1000,
      desktop: 1200
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          width: '100%',
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        body: {
          width: '100%',
          minHeight: '100%',
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#707070',
          marginTop: 8
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          display: 'flex'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 16
        }
      }
    }
  },
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#F8F8F8'
    },
    divider: '#E6E8F0',
    primary: {
      main: '#5500AB',
      light: '#7F00FF',
      dark: '#130026',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#707070',
      secondary: '#404040',
      tertiary: '#D4D4D4',
      disabled: 'rgba(55, 65, 81, 0.48)'
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: "'Poppins', sans-serif;",
    button: {
      fontWeight: 500
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.57
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66
    },
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      lineHeight: 1.375
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.375
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: 1.375
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: 1.375
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.375
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: 1.375
    }
  }
});

export default companyTheme;
