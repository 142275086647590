import SVG from 'react-inlinesvg';

import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import MuiTypography from '@mui/material/Typography';

import BackgroundImage from '~/assets/images/design/background.png';

export const Section = styled('section')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.light
}));

export const Title = styled(MuiTypography)(({ theme }) => ({
  color: 'white',
  span: {
    color: theme.palette.primary.main
  }
}));

export const Blink = styled('span')(() => ({
  animation: 'animate 1.5s linear infinite',
  '@keyframes animate': {
    '0%': {
      opacity: 0
    },
    '50%': {
      opacity: 0.7
    },
    '100%': {
      opacity: 0
    }
  }
}));

export const StartNowButton = styled(MuiButton)(() => ({
  color: 'white',
  borderColor: 'white',
  '&:hover': {
    background: 'transparent',
    borderColor: 'white'
  }
}));

export const SocialIcon = styled(SVG)(() => ({
  color: 'white'
}));

export const BgImage = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: 'contain',
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat'
}));
